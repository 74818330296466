<template>
  <div v-show="isActive">
    <slot :isActive="isActive"></slot>
  </div>
</template>

<script>
  export default {
    name: "Tab",
    props: {
      title: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isActive: false
      }
    }
  }
</script>

<style scoped>

</style>
