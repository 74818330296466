<template>
  <div>
    <div class="tabs-wrapper inline-flex my-4">
      <div
        class="tab-head text-bold pb-2 cursor-pointer"
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{'active text-blue-500': tab.isActive}"
        @click="openTab(index)"
      >{{tab.title}}</div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "Tabs",
    emits: ['activeIndexChange'],
    data() {
      return {
        tabs: []
      };
    },
    methods: {
      openTab(index) {
        if (!this.tabs[index]) return;

        this.$emit('activeIndexChange', index);

        this.tabs.forEach(tab => tab.isActive = false);
        this.tabs[index].isActive = true;
      }
    },
    mounted() {
      this.tabs = this.$children;
      this.openTab(0);
    }
  }
</script>

<style scoped>
  .tabs-wrapper {
    margin-right: -1rem;
    margin-left: -1rem;
  }

  .tab-head {
    margin-right: 1rem;
    margin-left: 1rem;
    font-weight: bold;
  }

  .tab-head.active {
    border-bottom: 3px solid #3498DB;
  }
</style>
