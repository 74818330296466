<template>
  <Tabs ref="tabs" class="mx-4"  @activeIndexChange="hadleActiveTabChange">
    <Tab title="month" class="month-year-picker__date">
      <span class="date-label">{{ months[month].text }} {{ year }}</span>
      <div>
        <button
          v-if="showLeftIcon"
          class="dp-custom-icon-btn"
          type="button"
          @click="onPrev"
        >
          <icon class="w-5 h-5 transform rotate-180" name="chevronRight" />
        </button>
        <button
          v-if="showRightIcon"
          class="dp-custom-icon-btn"
          type="button"
          @click="onNext"
        >
          <icon class="w-5 h-5" name="chevronRight" />
        </button>
      </div>
    </Tab>
    <Tab title="year">
      <div class="month-year-picker__year">
        <div class="month-year-picker__date">
          <span class="date-label">{{ activeYear }}</span>
          <div>
            <button
              v-if="showLeftIcon"
              class="dp-custom-icon-btn"
              type="button"
              @click="onPrevYear"
            >
              <icon class="w-5 h-5 transform rotate-180" name="chevronRight" />
            </button>
            <button
              v-if="showRightIcon"
              class="dp-custom-icon-btn"
              type="button"
              @click="onNextYear"
            >
              <icon class="w-5 h-5" name="chevronRight" />
            </button>
          </div>
        </div>
        <div class="grid gap-y-2 gap-x-1 gap grid-cols-3 grid-rows-3 mt-2">
          <button
            class="btn-primary btn-transparent"
            v-for="m in months"
            :key="m.value"
            @click="handleMonthClick(m.value, activeYear)"
          >
            {{ m.text }}
          </button>
        </div>
      </div>
    </Tab>
  </Tabs>
</template>

<script>
import { ref } from "vue";
import Icon from "@/components/ui/Icon";
import Tabs from "@/components/ui/tabs/Tabs";
import Tab from "@/components/ui/tabs/Tab";

export default {
  components: { Icon, Tabs, Tab },

  props: {
    months: {
      type: Array,
      default: () => [],
    },
    years: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: null,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    month: {
      type: Number,
      default: 0,
    },
    year: {
      type: Number,
      default: 0,
    },
    customProps: {
      type: Object,
      default: null,
    },
  },

  emits: ["update:month", "update:year"],

  setup(props, { emit }) {
    const tabs = ref(null);
    const activeYear = ref(props.year)

    const updateMonthYear = (month, year) => {
      emit("update:month", month);
      emit("update:year", year);
    };

    const onNext = () => {
      let month = props.month;
      let year = props.year;
      if (props.month === 11) {
        month = 0;
        year = props.year + 1;
        activeYear.value = year;
      } else {
        month += 1;
      }
      updateMonthYear(month, year);
    };

    const onPrev = () => {
      let month = props.month;
      let year = props.year;
      if (props.month === 0) {
        month = 11;
        year = props.year - 1;
        activeYear.value = year;
      } else {
        month -= 1;
      }
      updateMonthYear(month, year);
    };

    const onNextYear = () => {
      activeYear.value++;
    };

    const onPrevYear = () => {
      activeYear.value--;
    };

    const handleMonthClick = (month, year) => {
      updateMonthYear(month, year);
      tabs.value.openTab(0);
    };

    const hadleActiveTabChange = (index) => {
      if(index === 0){
        activeYear.value = props.year;
      }
    };

    return {
      onNext,
      onPrev,
      onNextYear,
      onPrevYear,
      handleMonthClick,
      hadleActiveTabChange,
      tabs,
      activeYear,
    };
  },

  computed: {
    showLeftIcon() {
      const { multiCalendars, multiCalendarsSolo, instance } = this.$attrs;
      if (multiCalendars) {
        return !multiCalendarsSolo ? instance === 0 : true;
      }
      return true;
    },
    showRightIcon() {
      const { multiCalendars, multiCalendarsSolo, instance } = this.$attrs;

      if (multiCalendars) {
        return !multiCalendarsSolo ? instance === multiCalendars - 1 : true;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.dp-custom-month-year-wrapper {
  @apply relative flex items-center justify-center;
  height: 5rem;
}

.dp-custom-icon-btn {
  @apply inline-flex items-center text-gray-400 outline-none left-0 p-1;
}

.date-label {
  @apply font-frank text-xs text-black font-medium lowercase;
}

.month-year-picker__year {
  @apply flex flex-col bg-white absolute left-0 right-0 bottom-0 z-10 mx-4 mt-2;
  top: 54px;
}

.month-year-picker__date {
  @apply flex justify-between items-center;
}
</style>
